/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.min.css";
/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

@import '@angular/cdk/overlay-prebuilt.css';

body{
  background-image: url('../src/assets/background-walkr.jpg');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .table {
    vertical-align: middle;
    text-align: center;

    tr{
        cursor: pointer;
    }
}

}
.button-color{
  color: #ffff;
  background-color: #03324A;
}

.card-radius{
  border-radius: 25px;
}

.mat-paginator-page-size{
  display: none !important;
}

.mat-paginator-range-label{
  display: none !important;
}

.ngx-pagination .current {
  background: var(--dark) !important;
}

.current .ng-star-inserted {
  padding: 0.1875rem 0.625rem;
  background: var(--dark);
  color: var(--ligth);
  cursor: pointer;
}

//swal confirmation button
.swal2-styled.swal2-confirm {
  background-color: var(--success) !important;
}

.swal2-styled.swal2-cancel {
  background-color: var(--danger) !important;
}

.breadcrumb {
  background-color: rgba(233, 236, 239, 0.8) !important;
}

.breadcrumb-link::before{
  content: '/';
  color: #6c757d;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.buttons-export{
  width: 100%;
  margin-bottom: 1rem;
}

.card{
  background-color: rgba(255, 255, 255, 0.5) !important;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -17rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 17rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }
  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }
  #wrapper.toggled #sidebar-wrapper {
    margin-left: -17rem;
  }
}

button:disabled, button[disabled] {
  cursor: not-allowed;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.cdk-overlay-pane {
  background-color: white !important;
}

.disabled-button {
  pointer-events: none;
  opacity: 0.5;
  background-color: #e0e0e0; /* Cor de fundo em tons de cinza */
  color: #a0a0a0; /* Cor do texto em tons de cinza */
  border-color: #a0a0a0; /* Cor da borda em tons de cinza */
}
